import './App.css';
import React, { useEffect, useState } from "react";
import logo from "./logo.png"
import { useCookies } from 'react-cookie'
import Login from './pages/Login';
import Form from './pages/Form';
import Cover from './pages/Cover';
import Register from './pages/Register'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/activate" component={Register} />
          <Route path="/" children={<Child />} />
        </Switch>
        <ToastContainer />
      </div>
    </Router>
  );
}
function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const [formIndex, setFormIndex] = useState(null)
  const [sessionExists, setSessionExists] = useState(false);
  const [sessionCookie, setSessionCookie, removeSessionCookie] = useCookies(["token"]);

  const SignOut = () => {
    removeSessionCookie("token");
    setSessionExists(false);
    setFormIndex(null);
  }
  const CheckSession = () => {
    if (sessionCookie["token"] != null) {
      setSessionExists(true);
    }
    else {
      setSessionExists(false);
    }
  }
  return (
    <div>{
      useEffect(() => {
        CheckSession();
      }, [sessionCookie])}

      <div className="App-header">
        <div className="AppLogo">
          <a href="https://carolynleepllc.com/">
            <img src={logo} alt="Carolyn Lee" style={{ width: "200px" }} />
          </a></div>
        {formIndex != null &&
          <button className="navbutton" onClick={() => setFormIndex(null)}>All Forms</button>}
        {sessionExists &&
          <button className="navbutton" onClick={() => SignOut()}>Sign Out</button>}
      </div>
      {sessionExists ? <>
        {formIndex != null ?
          <Form
            accessToken={sessionCookie["token"]}
            formIndex={formIndex}
            setFormIndex={setFormIndex}
          /> :
          <Cover
            accessToken={sessionCookie["token"]}
            setFormIndex={setFormIndex} />
        }</> :
        <Login
          setSessionCookie={(contents) => setSessionCookie("token", contents, { path: '/' }, setSessionExists(true))}
        />}
    </div>
  );
}
export default App;
