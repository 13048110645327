import { React, useEffect, useState } from "react";
import logo from "../logo.png"
import '../styles/login.css'
import { api } from "../api/api";
import { Redirect, useHistory } from "react-router";


export default function Register(props) {
    const [password, setPassword] = useState("");
    const [userId, setUserId] = useState(null)
    const [token, setToken] = useState(null)
    const [comparePassword, setComparePassword] = useState("");
    const [showError, setShowError] = useState("");
    const history = useHistory();

    const setNewPassword = (e) => {
        if (password === comparePassword && password !== "") {
            api.noTokenPost('user/' + userId.toString() + '/password', { password: password, confirmPassword: comparePassword, token: token }).then(res => {
                if (res.success === false) {
                    res.errors.map(error => { setShowError(error) });
                }
                else {
                    setShowError("")
                    return history.push('/');
                }
            })

        }
        else {
            setShowError("Passwords do not match")
        }
    };
    return (
        <>
            {
                useEffect(() => {
                    const params = props.location.search.split('&')
                    const newUserId = params[0].replace('?u=', '')
                    const newToken = params[1].replace('t=', '')
                    setUserId(newUserId);
                    setToken(newToken);
                }, [])
            }
            <div className="App-header">
                <div className="AppLogo">
                    <a href="https://carolynleepllc.com/">
                        <img src={logo} alt="Carolyn Lee" style={{ width: "200px" }} /></a></div>
            </div>
            <div className="card"
                style={{
                    textAlign: "center",
                    marginTop: "100px",
                    width: '500px'
                }}>
                <div className="text-danger" style={{ marginBottom: "10px" }}>{showError}</div>
                <div className={`form-group has-feedback`}>
                    <input
                        type="password"
                        name="Password"
                        className={`form-control`}
                        placeholder="enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className={`form-group has-feedback`}>
                    <input
                        type="password"
                        name="ConfirmPassword"
                        className={`form-control`}
                        placeholder="confirm password"
                        value={comparePassword}
                        onChange={(e) => setComparePassword(e.target.value)}
                    />
                </div>

                <button onClick={() => { setNewPassword() }} style={{ marginTop: "20px" }}>Activate</button>
            </div>
        </>)
}