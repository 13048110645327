import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import '../../styles/form.css'
import { api } from '../../api/api'
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import TextNumberQuestion from './TextNumberQuestion'
import RadioCheckboxQuestion from './RadioCheckboxQuestion'
import DocumentUploadQuestion from './DocumentUploadQuestion'
import DropDownQuestion from "./DropDownQuestion";
import DatePickerQuestion from "./DatePickerQuestion";
import DependantQuestion from "./DependantQuestion";

export const QuestionMain = (props) => {
    const [displayIndex, setDisplayIndex] = useState(props.answersDisplayOrder)

    const AddAnotherAnswer = (questionId) => {
        const newAnswers = props.answers;
        console.log(newAnswers)
        const questionAnswers = newAnswers.filter(a => a.questionId === questionId && a.displayIndex === displayIndex);
        const previousAnswer = questionAnswers[questionAnswers.length - 1];
        let nA = {
            id: uuidv4(),
            questionId: questionId,
            order: 0,
            displayIndex: displayIndex,
            formId: props.form.id,
            text: '',
            saved: false
        };
        if (previousAnswer != undefined) {
            nA = { id: uuidv4(), questionId: previousAnswer.questionId, order: previousAnswer.order, displayIndex: previousAnswer.displayIndex, formId: previousAnswer.formId, text: '' };
        }

        newAnswers.splice(newAnswers.indexOf(previousAnswer) + 1, 0, nA);
        props.setAnswers([...newAnswers]);

    }
    const DeleteExtraAnswer = (answerId) => {

        const a = props.answers.find(a => a.id === answerId);
        var formData = new FormData();
        if (a !== undefined) {
            formData.append("answerId", answerId);
            formData.append("questionId", a.questionId);
            formData.append("formId", a.formId);
            formData.append("text", '');
            formData.append("order", a.order);
            api.postAnswer(formData, props.accessToken).then(res => {
                if (res.success === false) {
                    res.errors.map(e => {
                        return toast(e)
                    }
                    )
                }
                else {
                    const newAnswers = props.answers;
                    const deletedAnswer = newAnswers.find(a => a.id === answerId)
                    newAnswers.splice(newAnswers.findIndex(a => a.id === answerId), 1);
                    props.setAnswers([...newAnswers]);

                }
            })
        }
        else {
            toast("Something went wrong when saving your answer")
        }



    }
    const onChange = (e) => {
        var newAnswers = props.answers;
        //Radio/Checkbox
        if (e.target.hasOwnProperty("checked")) {
            if (e.target.getAttribute('type') === 'radio') {
                newAnswers.find(a => a.id == e.target.getAttribute('answer-id')).text = e.target.getAttribute('data-key');
                newAnswers.find(a => a.id == e.target.getAttribute('answer-id')).saved = false;
                submitAnswer(e.target.getAttribute('answer-id'))
            }
            else {

                var existing = newAnswers.find(a => a.id === e.target.getAttribute('answer-id')).text;
                if (existing?.includes(e.target.getAttribute('data-key'))) {
                    //remove already existing value, uncheck
                    try {
                        existing.splice(existing.indexOf(e.target.getAttribute('data-key')), 1);
                    }
                    catch {
                        existing = existing.replace("," + e.target.getAttribute('data-key'), "");
                    }
                }
                else {
                    try {
                        existing = existing + "," + e.target.getAttribute('data-key')
                    }
                    catch {
                        existing = [existing, e.target.getAttribute('data-key')];
                    }
                }

                if (newAnswers.find(a => a.id === e.target.getAttribute('answer-id')) !== undefined) {
                    existing = existing.replace('null', '')
                    if (existing[0] === ",") {
                        existing = existing.substring(1);
                    }
                    newAnswers.find(a => a.id === e.target.getAttribute('answer-id')).text = existing;
                    newAnswers.find(a => a.id == e.target.getAttribute('answer-id')).saved = false;
                    submitAnswer(e.target.getAttribute('answer-id'))
                }
            }
        }
        else {
            // Text/Number/Dropdown
            newAnswers.find(a => a.id === e.target.getAttribute('answer-id')).text = e.target.value;
            newAnswers.find(a => a.id == e.target.getAttribute('answer-id')).saved = false;
            submitAnswer(e.target.getAttribute('answer-id'))
        }
        props.setAnswers([...newAnswers]);
    }
    const submitAnswer = (answerId) => {
        const a = props.answers.find(a => a.id === answerId);
        var formData = new FormData();
        if (a !== undefined) {
            formData.append("answerId", answerId);
            formData.append("questionId", a.questionId);
            formData.append("formId", a.formId);
            formData.append("text", a.text);
            formData.append("order", a.order);
            api.postAnswer(formData, props.accessToken).then(res => {
                if (res.success === false) {
                    res.errors.map(e => {
                        return toast(e)
                    }
                    )
                }
                else {
                    const newAnswers = [...props.answers]
                    newAnswers.find(a => a.id === answerId).saved = true;
                    props.setAnswers([...newAnswers]);
                }
            })
        }
        else {
            toast("Something went wrong when saving your answer")
        }
    }
    if (props.question === undefined || props.question === null) {
        return null
    }
    else if (props.question.type === 1 || props.question.type === 4) {
        return (<>
            <TextNumberQuestion
                answers={props.answers}
                displayIndex={displayIndex}
                question={props.question}
                form={props.form}
                onChange={onChange}
                DeleteExtraAnswer={DeleteExtraAnswer}
            />
            {props.question.allowMultiple && <><button onClick={() => { AddAnotherAnswer(props.question.id) }}>Add Another One</button><br /></>}
            < hr />
        </>)
    }
    else if (props.question.type === 2 || props.question.type == 3) {
        return (<>
            <RadioCheckboxQuestion
                answers={props.answers}
                question={props.question}
                form={props.form}
                onChange={onChange}
            />
            <hr /></>)
    }
    else if (props.question.type === 6) {
        return (
            <>
                <DocumentUploadQuestion
                    question={props.question}
                    form={props.form}
                    selectedFiles={props.selectedFiles}
                    setSelectedFiles={props.setSelectedFiles}
                    accessToken={props.accessToken} />
                <hr />
            </>
        )
    }
    else if (props.question.type === 5) {
        return (
            <>
                <DropDownQuestion
                    form={props.form}
                    question={props.question}
                    answers={props.answers}
                    onChange={onChange} />
                <hr />
            </>
        )
    }
    else if (props.question.type === 7) {
        return (
            <>
                <DatePickerQuestion
                    form={props.form}
                    question={props.question}
                    answers={props.answers}
                    setAnswers={props.setAnswers}
                    submitAnswer={submitAnswer} />

                <hr />
            </>
        )
    }
    else if (props.question.type === 8) {
        return (
            <>
                <DependantQuestion
                    answer={props.answers.filter(a => a.questionId == props.question.id && a.formId == props.form.id)}
                    displayIndex={displayIndex}
                    question={props.question}
                    form={props.form}
                    onChange={onChange}
                    DeleteExtraAnswer={DeleteExtraAnswer}
                    AddAnotherAnswer={AddAnotherAnswer}
                />
                <hr />
            </>
        )
    }
    else {
        return (<>Something is wrong with this question type, please contact our support staff
            <hr /></>)
    }

}