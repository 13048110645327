import React, { useEffect } from "react";
import '../../styles/form.css'
import { faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RadioCheckboxQuestion = (props) => {
    return (
        <>
            <div className="questionText">{props.question.displayText}  {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            {props.answers.filter(a => a.questionId == props.question.id && a.formId == props.form.id)?.map(answer => {
                return (
                    <>
                        {
                            answer.saved && <span
                                style={{
                                    marginLeft: "-20px",
                                    position: "absolute",
                                    color: '#0569a9'
                                }}><FontAwesomeIcon icon={faCheck} /></span>
                        }
                        {
                            props.question.choices.map(choice =>
                                <> <label>
                                    <input
                                        type={props.question.type === 2 ? "radio" : "checkbox"}
                                        key={choice.id}
                                        data-key={choice.id}
                                        answer-id={answer.id}
                                        name={props.question.id}
                                        checked={props.question.type === 2 ?
                                            answer.text == choice.id ? true : false
                                            :
                                            answer.text?.includes(choice.id) ? true : false
                                        }
                                        onChange={props.onChange.bind(this)} />
                                    {choice.text}
                                </label></>)
                        }
                    </>
                )
            })}
        </>
    );
}


export default RadioCheckboxQuestion;