import React, { useEffect, useState } from "react";
import '../../styles/form.css'
import { faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import TextNumberInputField from "./TextNumberInputField";
import { isCompositeComponent } from "react-dom/test-utils";

const DependantQuestion = (props) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (props.answer.length > 0) {
            setChecked(true);
        }
    }, []
    )
    useEffect(() => {
        if (props.answer.length === 0 && checked) {
            props.AddAnotherAnswer(props.question.id)
        }
    }, [checked])
    return (
        <>
            <div className="questionText">{props.question.displayText}  {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            <label>
                <input
                    type="checkbox"
                    type-key={'Dependant'}
                    disabled={props.answer.length > 1}
                    name={props.question.id}
                    checked={checked}
                    onChange={() => {
                        if (checked && props.answer.length === 1) {
                            props.DeleteExtraAnswer(props.answer[0].id)
                        }
                        if (!checked && props.answer.length === 0) {
                            props.AddAnotherAnswer(props.question.id)
                        }
                        setChecked(!checked)
                    }} />
                Yes
            </label>
            {checked && <>
                {props.question.additionalText}
                {props.answer.map((answer) => {
                    return (
                        < TextNumberInputField
                            question={props.question}
                            answer={answer}
                            onChange={props.onChange}
                            displayIndex={props.displayIndex}
                            DeleteExtraAnswer={props.DeleteExtraAnswer}
                            answers={props.answer} />)
                })}
                <button onClick={() => { props.AddAnotherAnswer(props.question.id) }}>Add Another One</button><br />
            </>}
        </>
    );
}


export default DependantQuestion;