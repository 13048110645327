import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import '../styles/form.css'
import logo from "../logo.png"
import { api } from '../api/api'

import "react-datepicker/dist/react-datepicker.css";
export default function Cover(props) {
    const [forms, setForms] = useState(null)

    const LoadForm = () => {
        api.fetchData('applicant', props.accessToken).then(res => {
            const newForms = res.data.forms;
            setForms(newForms);
        })

    }
    useEffect(() => {
        LoadForm()
    }, [props.accessToken])
    return (<>
        <div className="card"
            style={{
                textAlign: "center",
                marginTop: "100px",
                width: "400px"
            }}> {
                forms != null &&
                forms.map((form, index) => {
                    return <>
                        <button
                            style={{
                                marginTop: '10px'
                            }}
                            onClick={() => props.setFormIndex(index)}>Form {form.name}</button>
                    </>
                })
            }

        </div></>)
}