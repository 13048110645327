import React, { useEffect } from "react";
import '../../styles/form.css'
import { faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";

const DatePickerQuestion = (props) => {
    const DateChange = (id, date) => {
        var newAnswers = props.answers;
        newAnswers.find(a => a.id === id).text = date.toISOString();
        props.setAnswers([...newAnswers]);
        props.submitAnswer(id)
    }
    return (
        <>
            <div className="questionText">{props.question.displayText}  {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            {props.answers.filter(a => a.questionId == props.question.id && a.formId == props.form.id)?.map(answer => {
                return (

                    <>
                        {
                            answer.saved && <span
                                style={{
                                    marginLeft: "-20px",
                                    position: "absolute",
                                    color: '#0569a9'
                                }}><FontAwesomeIcon icon={faCheck} /></span>
                        }
                        <DatePicker
                            key={answer.id}
                            selected={answer.text != "" ? new Date(answer.text) : new Date()}
                            onChange={(date) => DateChange(answer.id, date)} />
                    </>)
            })}
        </>
    );
}


export default DatePickerQuestion;