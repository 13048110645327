import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import '../styles/form.css'
import { api } from '../api/api'
import {
  useParams
} from "react-router-dom";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import TextNumberQuestion from './QuestionTypes/TextNumberQuestion'
import RadioCheckboxQuestion from './QuestionTypes/RadioCheckboxQuestion'
import DocumentUploadQuestion from './QuestionTypes/DocumentUploadQuestion'
import DropDownQuestion from "./QuestionTypes/DropDownQuestion";
import DatePickerQuestion from "./QuestionTypes/DatePickerQuestion";
import { QuestionMain } from "./QuestionTypes/QuestionMain";
import { Group } from "./Group";
import { faPray } from "@fortawesome/free-solid-svg-icons";

function Form(props) {

  const [forms, setForms] = useState(null);
  const [groups, setGroups] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [answers, setAnswers] = useState([])
  const [submitable, setSubmitable] = useState(false)


  const submitForm = (e) => {
    e.preventDefault();
  }
  const AddAnswerInitial = (item, questionsList, groupsList, form, groupDisplayIndex) => {
    if (item.type == 0) {
      //Question
      const question = questionsList.find(q => q.id === item.id)

      if (question.type !== 6) {
        let newAnswers = answers;
        if ((newAnswers.filter(a => a.questionId === question.id && a.displayIndex === groupDisplayIndex).length === 0) && (form.answers.filter(a => a.questionId === question.id).length === 0)) {
          if (question.type === 8) {
            return;
          }
          newAnswers.push({
            id: uuidv4(),
            questionId: question.id,
            order: groupDisplayIndex,
            displayIndex: groupDisplayIndex,
            formId: form.id,
            text: '',
            saved: false
          })
        }
        else {
          let displayIndex = groupDisplayIndex;
          let existing = form.answers.filter(a => a.questionId === question.id)
          if (existing.length === 0) {
            existing = newAnswers.filter(a => a.questionId === question.id && a.displayIndex === groupDisplayIndex)
          }
          existing.sort((a, b) => a.order > b.order ? 1 : -1).map(ex => {
            //if newAnswers contains answer with that id already, skip
            //if forms.answers contains answer with that id but newAnswers does not, add the existing answer
            if (form.answers.find(a => a.id === ex.id) != undefined && newAnswers.find(a => a.id === ex.id) === undefined) {
              newAnswers.push({
                id: ex.id,
                questionId: ex.questionId,
                order: ex.order,
                displayIndex: displayIndex,
                formId: form.id,
                text: ex.text,
                saved: true
              })
            }
            displayIndex = displayIndex + 1;
          })
        }

        setAnswers([...newAnswers])
      }
      else {
        const newFiles = selectedFiles;
        if (newFiles.find(a => a.questionId == question.id) === undefined && form.answers.find(a => a.questionId === question.id) === undefined) {
          newFiles.push({
            id: uuidv4(),
            questionId: question.id,
            order: 0,
            displayIndex: groupDisplayIndex,
            formId: form.id,
            files: [{
              displayName: '',
              id: 0
            }],
            text: null
          })
        }
        else {
          let existing = form.answers.filter(a => a.questionId === question.id)
          let displayIndex = groupDisplayIndex;
          if (existing.length === 0) {
            existing = newFiles.filter(a => a.questionId === question.id && a.displayIndex === groupDisplayIndex)
          }
          existing.sort((a, b) => a.order > b.order ? 1 : -1).map(ex => {
            if (form.answers.find(a => a.id === ex.id) != undefined && newFiles.find(a => a.id === ex.id) === undefined) {
              newFiles.push({
                id: ex.id,
                questionId: ex.questionId,
                order: ex.order,
                displayIndex: displayIndex,
                formId: form.id,
                files: ex.attachments,
                text: null
              })
            }
            displayIndex = displayIndex + 1;
          }
          )
        }

        setSelectedFiles([...newFiles])
      }
    }
    else {
      //Group
      [...Array(groupsList.find(g => g.id == item.id).repeat),].map((value, index) => {
        groupsList.find(group => group.id == item.id).items.map((i) => {
          AddAnswerInitial(i, questionsList, groupsList, form, index)
        })
      })
    }
  }
  const LoadForm = () => {
    api.fetchData('applicant', props.accessToken).then(res => {
      const newForms = res.data.forms;
      const newGroups = res.data.groups;
      const newQuestions = res.data.questions;
      setQuestions(newQuestions);
      if (newForms != null || undefined) {
        newForms[props.formIndex].items.map(item => {
          AddAnswerInitial(item, newQuestions, newGroups, newForms[props.formIndex], 0)
        })
      }
      setGroups(newGroups);
      setForms(newForms);
    })

  }
  useEffect(() => {
    setSubmitable(true)
    if (forms != null) {
      questions.map(question => {
        if (question.required) {
          if (question.type != 6) {
            answers.filter(a => a.questionId === question.id && a.formId === forms[props.formIndex].id).map(
              answer => {
                if (answer.text == '' || answer.text == null) {
                  setSubmitable(false)
                }
              }
            )
          }
          else {
            selectedFiles.filter(a => a.questionID === question.id && a.formId === forms[props.formIndex].id).map(
              answer => {
                if (answer.files.length === 0) {
                  setSubmitable(false)
                }
              }
            )
          }
        }
      }
      )
    }
  }, [answers, selectedFiles])
  useEffect(() => {
    LoadForm();
  }, [props.accessToken])

  return (
    <div className="card">

      <div className="formName">
        {forms != null && forms[props.formIndex].name}
      </div>
      <form onSubmit={submitForm}>
        {forms == null ? null :
          forms[props.formIndex].items.map(item => {
            switch (item.type) {
              case 0:
                return (<QuestionMain
                  question={questions.find(q => q.id === item.id)}
                  answersDisplayOrder={0}
                  answers={answers}
                  form={forms[props.formIndex]}
                  setAnswers={setAnswers}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  accessToken={props.accessToken}
                />);
              case 1:
                return (
                  [...Array(groups.find(g => g.id == item.id).repeat),].map((value, index) => {
                    return <Group
                      setGroups={setGroups}
                      questions={questions}
                      answers={answers}
                      setAnswers={setAnswers}
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      accessToken={props.accessToken}
                      form={forms[props.formIndex]}
                      index={index}
                      groups={groups}
                      group={groups.find(g => g.id === item.id)} />

                  })
                )
              default:
                break;
            }
          })}
        <div style={{ margin: "10px 0px" }}>

        </div>
        <button class="submitbtn"
          disabled={!submitable} onClick={() => toast("FORM SUBMITTED")}>Submit</button>
      </form>
    </div>
  );
}


export default Form;