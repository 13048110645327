import { React, useState } from "react";
import { useCookies } from "react-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import '../styles/login.css'
import { api } from "../api/api";


const identity = require('aspnetcore-identity-password-hasher');


export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState("");

  const CheckAndSetEmail = (e) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    setEmail(e.target.value);
    if (pattern.test(e.target.value) || email == "") {
      setShowError("");
    } else {
      setShowError("Please enter a valid email");
    }
  };

  const LogInAction = async () => {
    api.post("user/login", { login: email, password: password }).then(res => {
      if (res.success == true) {
        props.setSessionCookie(res.data.accessToken)
      }
      else {
        setShowError("Invalid email and/or password")
      }
    })

  }
  return (
    <>
      <div className="card"
        style={{
          textAlign: "center",
          marginTop: "100px"
        }}>
        <div className="text-danger" style={{ marginBottom: "10px" }}>{showError}</div>
        <div className={`form-group has-feedback`}>
          <input
            name="Email"
            className={`form-control`}
            autoFocus
            placeholder="email"
            value={email}
            onChange={CheckAndSetEmail}
          />
          <span
            className={`form-control-feedback`}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
        </div>
        <div className={`form-group has-feedback`}>
          <input
            type="password"
            name="Password"
            className={`form-control`}
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <span
            className={` form-control-feedback`}
          >
            <FontAwesomeIcon icon={faLock} />
          </span>
        </div>

        <button onClick={LogInAction} style={{ marginTop: "20px" }}>Sign In</button>
      </div>
    </>)
}