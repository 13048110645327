import React, { useEffect } from "react";
import '../../styles/form.css'
import { faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropDownQuestion = (props) => {
    return (
        <>
            <div className="questionText">{props.question.displayText} {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            {props.answers.filter(a => a.questionId == props.question.id && a.formId == props.form.id)?.map(answer => {
                return (<>
                    {
                        answer.saved
                        && <span
                            style={{
                                marginLeft: "-20px",
                                position: "absolute",
                                color: '#0569a9'
                            }}><FontAwesomeIcon icon={faCheck} /></span>
                    }
                    < label >
                        <select
                            name={props.question.id}
                            answer-id={answer.id}
                            value={answer.text}
                            onChange={props.onChange.bind(this)}
                        >
                            {props.question.choices.map(choice => {
                                return (
                                    <option
                                        key={choice.id}
                                        data-key={choice.id}
                                        value={choice.id}>
                                        {choice.text}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                </>
                )
            })}
        </>
    );
}


export default DropDownQuestion;