import React, { useEffect } from "react";
import '../../styles/form.css'
import { faAsterisk, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUploader } from '../../components/FileUploader';

import { api } from '../../api/api'
import { toast } from "react-toastify";

const DocumentUploadQuestion = (props) => {
    const UploadFile = (files, answerId) => {
        const newFiles = [...props.selectedFiles];
        for (var i = 0, file; file = files[i]; i++) {
            newFiles.find(answer => answer.id === answerId).files.push({ displayName: file.name, id: 0, file: file })
        }
        props.setSelectedFiles([...newFiles]);
        submitFile(newFiles, answerId)
    }
    const DeleteFile = (fileId, answerId) => {
        const newFiles = [...props.selectedFiles];
        newFiles.find(answer => answer.id === answerId).files.splice(newFiles.find(answer => answer.id === answerId).files.findIndex(file => file.id === fileId), 1);
        props.setSelectedFiles([...newFiles]);
        submitFile(newFiles, answerId)

    }
    const submitFile = (newFiles, answerId) => {
        const sf = newFiles.find(a => a.id === answerId);
        var attachmentIds = Array(sf.files.length).fill(0);

        var formData = new FormData();
        formData.append("answerId", answerId);
        formData.append("questionId", sf.questionId);
        formData.append("formId", sf.formId);
        formData.append("text", "");

        for (var i = 0; i < sf.files.length; i++) {
            if (sf.files[i].file !== undefined) {
                formData.append("files", sf.files[i].file);
            }
            formData.append("attachmentIds", sf.files[i].id);

        }
        api.postAnswer(formData, props.accessToken).then(res => {
            if (res.success === false) {
                res.errors.map(e => {
                    return toast(e)
                }
                )
            }
            else {
                const nF = [...props.selectedFiles];
                nF.find(answer => answer.id === answerId).files = res.data.attachments;
                props.setSelectedFiles([...nF])
            }
        })
    }
    return (
        <>
            <div className="questionText">{props.question.displayText}  {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            {props.selectedFiles.filter(a => a.questionId == props.question.id && a.formId == props.form.id)?.map(answer => {
                return (
                    <>
                        {
                            answer.files.length > 0 && <span
                                style={{
                                    marginLeft: "-20px",
                                    position: "absolute",
                                    color: '#0569a9'
                                }}><FontAwesomeIcon icon={faCheck} /></span>
                        }
                        < FileUploader
                            onFileSelect={(file) => UploadFile(file, answer.id, answer.formId, answer.questionId)}
                            selectedFile={answer.files}
                            DeleteFile={(fileId) => { DeleteFile(fileId, answer.id) }}
                        /></>)
            })}
        </>
    );
}


export default DocumentUploadQuestion;