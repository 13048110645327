import React, { useEffect, useState } from "react";
import '../../styles/form.css'
import { faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextNumberInputField = (props) => {
    const [text, setText] = useState(null);
    const [event, setEvent] = useState(null);
    useEffect(() => {
        const timeoutId = setTimeout(() => { if (event != null) { props.onChange(event) } }, 1000);
        return () => clearTimeout(timeoutId);
    }, [text]);

    return (
        <><div
            style={{ display: "flex", flexDirection: "row" }}>
            {((props.answer.text === text || text === null) && props.answer.text !== '' && props.answer.text != null) && <span
                style={{
                    marginLeft: "-20px",
                    position: "absolute",
                    color: '#0569a9'
                }}><FontAwesomeIcon icon={faCheck} /></span>}
            <label>
                <input
                    name={props.question.id}
                    answer-id={props.answer.id}
                    type={props.question.type === 1 || 8 ? "text" : "number"}
                    value={text != null ? text : props.answer.text}
                    onChange={(e) => {
                        setText(e.target.value)
                        setEvent(e)
                    }}
                />
            </label>{(props.answers.filter(q => q.questionId === props.question.id && q.displayIndex === props.displayIndex).findIndex(a => a.id === props.answer.id) !== 0
                || (props.question.type === 8 && props.answers.filter(q => q.questionId === props.question.id && q.displayIndex === props.displayIndex).length > 0))
                &&
                <span
                    style={{ marginLeft: '5px' }}
                    className="deleteFile" onClick={() => { props.DeleteExtraAnswer(props.answer.id) }}><FontAwesomeIcon icon={faTrashAlt} /></span>}</div>
        </>
    );
}


export default TextNumberInputField;