import React, { useEffect, useState } from "react";
import '../../styles/form.css'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextNumberInputField from "./TextNumberInputField";
const TextNumberQuestion = (props) => {
    return (
        <>
            <div className="questionText">{props.question.displayText}  {props.question.required && <span style={{ color: '#ff1526' }}><FontAwesomeIcon icon={faAsterisk} /></span>}</div><br />
            {props.answers.length > 0 &&
                props.answers.filter(a => a.questionId == props.question.id && a.formId == props.form.id && a.displayIndex == props.displayIndex).map(answer => {
                    return (
                        <TextNumberInputField
                            question={props.question}
                            answer={answer}
                            onChange={props.onChange}
                            displayIndex={props.displayIndex}
                            DeleteExtraAnswer={props.DeleteExtraAnswer}
                            answers={props.answers} />)
                })}
        </>
    );
}


export default TextNumberQuestion;