import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from 'react'

export const FileUploader = ({ onFileSelect, selectedFile, DeleteFile }) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        onFileSelect(e.target.files)
    }
    return (
        <div className="file-uploader">
            <input type="file" onChange={handleFileInput} ref={fileInput} multiple />
            <button style={{ marginRight: "10px" }} onClick={e => fileInput.current && fileInput.current.click()}>Upload File</button>
            <br />
            <span style={{ fontWeight: "500" }}>Previously Uploaded For This Question:</span>
            <br />
            {selectedFile.map(file => {
                return file.displayName != null && <> {file.displayName} <span className="deleteFile" onClick={() => { DeleteFile(file.id) }}><FontAwesomeIcon icon={faTrashAlt} /></span><br /></>
            })}
        </div>
    )
}