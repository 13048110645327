const baseurl = 'https://api.stage.pursuit.carolynleepllc.com/'
export const fetchData = (url, token = null) =>
    fetch(baseurl + url, {
        headers: {
            "Authorization": token != null ? "Bearer " + token : null
        }
    }).then(res => res.json());

export const noTokenPost = (url, data, token = null) =>
    fetch(baseurl + url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": token != null ? "Bearer " + token : null
        },
        method: 'POST',
        body: JSON.stringify(data),
    }).then(res => (res.json()))
export const post = (url, data) =>
    fetch(baseurl + url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        method: 'POST',
        body: JSON.stringify(data),
    }).then(res => (res.json()))

export const postAnswer = (formData, token = null) =>
    fetch(baseurl + 'answer', {
        headers: {
            Accept: "multipart/form-data",
            "Authorization": token != null ? "Bearer " + token : null
        },
        method: 'POST',
        body: formData,
    }).then(res => (res.json()))

export const api = {
    fetchData,
    post,
    noTokenPost,
    postAnswer
};
