import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import '../styles/form.css'
import { api } from '../api/api'
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import { QuestionMain } from "./QuestionTypes/QuestionMain";

export const Group = (props) => {
    const [answersDisplayOrder, setAnswersDisplayOrder] = useState(props.index)
    const DuplicateGroup = (group) => {
        const groupIndex = props.groups.findIndex(g => g.id === group.id);
        const newGroup = { ...group, repeat: group.repeat + 1 };
        const newGroups = [...props.groups]
        newGroups[groupIndex] = newGroup;
        props.setGroups([...newGroups])
        const newAnswers = props.answers;
        const AnswersForThisGroup = [];
        group.items.map(item =>
            props.answers.filter(a => a.questionId === item.id).map(answer =>
                AnswersForThisGroup.push(answer))
        );
        let lastOrder = 0;
        AnswersForThisGroup.map(answer => {
            if (answer.displayIndex > lastOrder) {
                lastOrder = answer.displayIndex
            }
        })
        AnswersForThisGroup.map((answer, index) => {
            if (answer.displayIndex === lastOrder) {
                newAnswers.push({ id: uuidv4(), questionId: answer.questionId, order: answer.order + 1, displayIndex: answer.displayIndex + 1, formId: answer.formId, text: '', saved: false });
            }
        })
        props.setAnswers(newAnswers)

    }
    const DeleteGroup = (group, groupI) => {
        const groupIndex = props.groups.findIndex(g => g.id === group.id);
        const newGroup = { ...group, repeat: group.repeat - 1 };
        const newGroups = [...props.groups]
        newGroups[groupIndex] = newGroup;
        props.setGroups([...newGroups])
        const AnswersForThisGroup = [];
        group.items.map(item =>
            props.answers.filter(a => a.questionId === item.id).map(answer =>
                AnswersForThisGroup.push(answer))
        );
        let lastOrder = 0;
        AnswersForThisGroup.map(answer => {
            if (answer.displayIndex > lastOrder) {
                lastOrder = answer.displayIndex
            }
        })
        AnswersForThisGroup.map((oldAnswer) => {
            if (oldAnswer.displayIndex === groupI) {
                const a = props.answers.find(a => a.id === oldAnswer.id);
                var formData = new FormData();
                if (a !== undefined) {
                    formData.append("answerId", oldAnswer.id);
                    formData.append("questionId", a.questionId);
                    formData.append("formId", a.formId);
                    formData.append("text", '');
                    formData.append("order", a.order);
                    api.postAnswer(formData, props.accessToken).then(res => {
                        if (res.success === false) {
                            res.errors.map(e => {
                                return toast(e)
                            }
                            )
                        }
                        else {
                            const newAnswers = props.answers;
                            let newHighestDisplayIndex = groupI;
                            //remove old answer from newAnswers
                            newAnswers.splice(newAnswers.findIndex(a => a.id === oldAnswer.id), 1);
                            //find all the answers that are for this question
                            const newAnswersForThisQuestion = newAnswers.filter(a => a.questionId === oldAnswer.questionId)
                            //if displayIndex of an answer is higher than the oldAnswer display index, lower the index to the next highest after the answer before the oldAnswer
                            let oldHighestIndex = oldAnswer.displayIndex;
                            newAnswersForThisQuestion.map(newAnswer => {
                                if (newAnswer.displayIndex > oldHighestIndex) {
                                    newAnswers.splice(newAnswers.findIndex(a => a.id === newAnswer.id), 1, { ...newAnswer, displayIndex: oldHighestIndex })
                                    oldHighestIndex = oldHighestIndex + 1;
                                }
                            })
                            props.setAnswers([...newAnswers]);
                        }
                    })
                }
                else {
                    toast("Something went wrong when saving your answer")
                }
            }
        })
    }
    return (<>
        <hr />
        <div className="group">
            <div className="groupText">{props.group.name}</div>
            <br />
            {props.group.items.map(item => {
                switch (item.type) {
                    case 0:
                        return (
                            <QuestionMain
                                question={props.questions.find(q => q.id === item.id)}
                                answers={props.answers}
                                form={props.form}
                                setAnswers={props.setAnswers}
                                selectedFiles={props.selectedFiles}
                                setSelectedFiles={props.setSelectedFiles}
                                accessToken={props.accessToken}
                                answersDisplayOrder={answersDisplayOrder}
                                setAnswersDisplayOrder={setAnswersDisplayOrder}
                            />
                        );
                    case 1:
                        return (
                            [...Array(props.groups.find(g => g.id == item.id).repeat),].map((value, index) => {
                                return (<Group
                                    questions={props.questions}
                                    answers={props.answers}
                                    setAnswers={props.setAnswers}
                                    selectedFiles={props.selectedFiles}
                                    setSelectedFiles={props.setSelectedFiles}
                                    accessToken={props.accessToken}
                                    form={props.form}
                                    setGroups={props.setGroups}
                                    index={index}
                                    groups={props.groups}
                                    group={props.groups.find(g => g.id === item.id)} />);
                            })
                        )

                    default:
                        break;
                }
            })}</div>
        {(props.index + 1 === props.group.repeat) &&
            <button onClick={() => DuplicateGroup(props.group)}>Add Another Group {props.group.name}</button>}
        {(props.index != 0) &&
            <button onClick={() => { DeleteGroup(props.group, props.index) }}> Delete Group</button>}
        <hr />
    </>)
}